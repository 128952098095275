import React from 'react';
import ContentContainer from '../../ContentContainer';
import icContestants from '../../../images/icons/ic-contestants.svg';
import icArrow from '../../../images/icons/ic-arrow.svg';
import icLocation from '../../../images/icons/ic-location-dark.svg';
import SegmentType from '../../SegmentType';

const Item = ({ challenge }) => {
  const segment_types = [];
  const cities = new Set(challenge.segments.map((s) => s.city)); // get unique cities
  cities.delete(null); // remove null from set

  return (
    <a href={challenge.url} className='flex flex-1 challenge-box'>
      <ContentContainer classes='flex-1 p-4 flex flex-row md:flex-col xl:flex-row rounded-cards overflow-hidden'>
        <div className='flex flex-col flex-1 mt-0 md:mt-4 xl:mt-0 min-w-0'>
          <div className='w-full flex justify-between items-center text-small-label mb-3'>
            <div className='flex items-center'>
              {challenge.challenge_type === 'segment' ? (
                challenge.segments.map((s) => {
                  if (segment_types.includes(s.segment_type_id)) return null;
                  segment_types.push(s.segment_type_id);
                  return (
                    <SegmentType
                      key={s.segment_type_id}
                      typeIconUrl={s.segment_type_icon_url}
                    />
                  );
                })
              ) : (
                <SegmentType
                  typeIconUrl={challenge.distance_challenge_type_icon_url}
                />
              )}
              {challenge.challenge_type === 'segment' &&
              challenge.distance_to_closest_challenge_segment_in_km !== null ? (
                <span className='pl-2'>
                  {challenge.distance_to_closest_challenge_segment_in_km < 100
                    ? challenge.distance_to_closest_challenge_segment_in_km.toFixed(
                        1
                      )
                    : challenge.distance_to_closest_challenge_segment_in_km <
                      1000
                    ? `${
                        Math.floor(
                          challenge.distance_to_closest_challenge_segment_in_km /
                            100
                        ) * 100
                      }+ `
                    : '1000+ '}
                  km away
                </span>
              ) : null}
            </div>
            <div className='flex items-center'>
              {challenge.display_admin_label ||
              challenge.created_by_current_user ? (
                <p className='inline-block mb-0 px-2 py-1 rounded-full mini-label bg-background-tertiary font-medium border border-border-quinary'>
                  {challenge.display_admin_label
                    ? 'Created by Admin'
                    : 'Created by you'}
                </p>
              ) : null}
            </div>
          </div>
          <div
            className={`${challenge.podium == null ? 'flex-1 mb-4' : 'mb-1'}`}
          >
            <div className='flex justify-between items-center mb-2'>
              <h2 className='truncate body-text text-text mb-0 font-text font-medium'>
                {challenge.name}
              </h2>
              <div className='flex items-center body-text text-text pl-4 shrink-0'>
                <img
                  src={icContestants}
                  className='mr-2 pointer-events-none w-[1.125rem]'
                />
                {challenge.contestants_count}
              </div>
            </div>
            <p className='m-0 text-text-grey-600 small-link-text mb-3'>
              {challenge.to}
            </p>
            {challenge.challenge_type === 'segment' ? (
              <>
                {cities.size > 0 && (
                  <p className='xmini-text m-0 uppercase whitespace-nowrap'>
                    <img
                      src={icLocation}
                      className='h-4 inline mb-[2px] mr-[2px]'
                    />
                    {Array.from(cities)[0]}
                    {cities.size > 1 && (
                      <span className='px-2 py-[2px] ml-2 rounded-xl bg-background-quinary'>
                        + {cities.size - 1}
                      </span>
                    )}
                  </p>
                )}
              </>
            ) : (
              <p className='text-text-grey-600 small-link-text mb-1'>
                Distance challenge
              </p>
            )}
          </div>
          {challenge.podium != null && (
            <div className='flex-1 mb-16pt'>
              {challenge.podium.map((c) => (
                <div
                  key={c.id}
                  className={`w-full ${
                    c.current_user ? 'bg-accent font-medium' : ''
                  } rounded px-8pt py-2 mt-3 flex justify-between small-body-text`}
                >
                  <span className='flex'>
                    <span className='w-10 inline-block shrink-0'>
                      {c.position}.
                    </span>
                    <span className='inline-block'>{c.athletename}</span>
                  </span>
                  <span>
                    {challenge.challenge_type === 'segment'
                      ? `${c.points}pts`
                      : `${c.total_distance_in_meters} km`}
                  </span>
                </div>
              ))}
              {[...Array(3 - challenge.podium.length)].map((_, i) => (
                <div
                  key={i}
                  className='w-full rounded px-8pt py-2 mt-3 flex justify-between small-body-text'
                >
                  <span>
                    <span className='w-10 inline-block'>
                      {i + challenge.podium.length + 1}.
                    </span>
                    <span className='text-text-grey-500'>(Up for grabs)</span>
                  </span>
                  <span className='text-text-grey-500'>/</span>
                </div>
              ))}
              {challenge.joined &&
              (challenge.joined_position == 0 ||
                challenge.joined_position > 3) ? (
                <div className='w-full bg-accent font-medium rounded px-8pt py-2 mt-3 flex justify-between small-body-text'>
                  <span className='flex'>
                    <span className='w-10 inline-block shrink-0'>
                      {challenge.joined_position
                        ? `${challenge.joined_position}.`
                        : '-'}
                    </span>
                    <span className='inline-block'>
                      {challenge.joined_athletename}
                    </span>
                  </span>
                  <span>
                    {challenge.challenge_type === 'segment'
                      ? challenge.joined_points
                        ? `${challenge.joined_points}pts`
                        : '/'
                      : challenge.joined_total_distance
                      ? `${challenge.joined_total_distance} km`
                      : '/'}
                  </span>
                </div>
              ) : null}
            </div>
          )}

          <div
            className={`flex ${
              challenge.joined ? 'justify-between' : 'justify-end'
            } small-body-text items-center`}
          >
            {challenge.joined ? (
              <p className='inline-block mb-0 px-2 py-1 rounded-full mini-label bg-background-tertiary font-medium border border-border-quinary'>
                Joined
              </p>
            ) : null}
            <div className='flex'>
              <span className='pr-[0.5rem]'>See more</span>
              <img src={icArrow} className='arrow-icon h-[1.5rem] w-[1.5rem]' />
            </div>
          </div>
        </div>
      </ContentContainer>
    </a>
  );
};

export default Item;
